import { Accordion, AccordionPanel, Box } from 'grommet'
import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH3,
  StyledSpan,
  StyledUnderlinedH2,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledAccordionContainer = styled(Box)`
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol' !important;
  & > div {
    overflow: visible;
  }
`

const StyledAccordionComponent = styled(AccordionPanel)`
  justify-content: start;
  & div:first-child {
    order: 2;
  }
  div {
    h4 {
      max-width: 100%;
      font-weight: normal;
      color: ${props => props.theme.global.colors.primaryBlack};
    }

    svg {
      fill: ${props => props.theme.global.colors.primaryDark};
      stroke: ${props => props.theme.global.colors.primaryDark};
    }
  }
`

const StyledDrawer = styled(Box)`
  background: transparent;
  padding: 0 0 24px 24px;

  ${media.greaterThan('small')`
    padding-left: 52px
  `}
`

const StyledAccordion = ({ index, data, shade, intl }) => {
  const accordion = `accordions.accordion${index}`
  const title = intl.formatMessage({
    id: `${accordion}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${accordion}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${accordion}.description.richText`,
    }) !== 'null'

  const buttons = intl.formatMessage({
    id: `${accordion}.buttons.number`,
  })
  const number = intl.formatMessage({
    id: `${accordion}.number`,
  })
  const backgroundImage = intl.formatMessage({
    id: `${accordion}.backgroundImage`,
  })
  return (
    <StyledContainer
      componentName="Accordion"
      shade={shade}
      data={data}
      backgroundImage={backgroundImage}
    >
      {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
      {hasDescription && !isRichText ? (
        <StyledSpan>{description}</StyledSpan>
      ) : (
        hasDescription &&
        isRichText && (
          <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
        )
      )}
      <StyledAccordionContainer>
        <Accordion animate={true} multiple={true}>
          {Array.from(Array(Number(number)).keys()).map((drawer, index) => {
            const section = `${accordion}.drawer${index}`
            const drawerTitle = intl.formatMessage({
              id: `${section}.title`,
            })
            const hasDrawerTitle = drawerTitle !== 'null'
            const drawerDescription = intl.formatMessage({
              id: `${section}.description.description`,
            })
            const hasDrawerDescription = drawerDescription !== 'null'
            const isDrawerRichText =
              intl.formatMessage({
                id: `${section}.description.richText`,
              }) !== 'null'

            const drawerButtons = intl.formatMessage({
              id: `${section}.buttons.number`,
            })

            return (
              <StyledAccordionComponent label={drawerTitle}>
                <StyledDrawer>
                  {hasDrawerDescription && !isDrawerRichText ? (
                    <StyledSpan>{drawerDescription}</StyledSpan>
                  ) : (
                    hasDrawerDescription &&
                    isDrawerRichText && (
                      <StyledSpan
                        dangerouslySetInnerHTML={{ __html: drawerDescription }}
                      />
                    )
                  )}
                  {drawerButtons > 0 && (
                    <Box
                      direction="row-responsive"
                      gap="medium"
                      pad={{ vertical: 'medium' }}
                    >
                      {Array.from(Array(Number(drawerButtons)).keys()).map(
                        i => {
                          const columnButton = `button${i}`
                          const columnButtonLabel = intl.formatMessage({
                            id: `${section}.buttons.${columnButton}.label`,
                          })
                          const columnButtonLink = intl.formatMessage({
                            id: `${section}.buttons.${columnButton}.link`,
                          })
                          return (
                            <Button
                              key={shortid.generate()}
                              label={columnButtonLabel}
                              link={columnButtonLink}
                              light={
                                shade === 'light' || shade === 'grey'
                                  ? true
                                  : false
                              }
                            />
                          )
                        }
                      )}
                    </Box>
                  )}
                </StyledDrawer>
              </StyledAccordionComponent>
            )
          })}
        </Accordion>
      </StyledAccordionContainer>
      {buttons > 0 && (
        <Box
          direction="row-responsive"
          gap="medium"
          pad={{ vertical: 'medium' }}
        >
          {Array.from(Array(Number(buttons)).keys()).map(i => {
            const button = `button${i}`
            const buttonLabel = intl.formatMessage({
              id: `${columns}.buttons.${button}.label`,
            })
            const buttonLink = intl.formatMessage({
              id: `${columns}.buttons.${button}.link`,
            })
            return (
              <Button
                key={shortid.generate()}
                label={buttonLabel}
                link={buttonLink}
                light={shade === 'light' || shade === 'grey' ? true : false}
              />
            )
          })}
        </Box>
      )}
    </StyledContainer>
  )
}

StyledAccordion.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(StyledAccordion)
